import type { AuthService } from "@core/domains";
import { createMemo } from "solid-js";
import { useWire } from "@core/wire";

export const selectIsIdentityConnecting = (auth: AuthService) => auth.isConnecting();

export const useIsIdentityConnecting = () => {
  const wire = useWire();
  return wire.services.auth.isConnecting;
};
