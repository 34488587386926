import { type Component, Show } from "solid-js";
import { Presence, Motion } from "@repo/solid-motionone";
import styles from "./EditorFocusedAnimation.module.css";

export const EditorFocusedAnimation: Component<{
  focused: boolean;
  empty: boolean;
  inset?: string;
}> = (props) => {
  return (
    <>
      <Presence>
        <Show when={true}>
          <Motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ inset: props.inset ?? "-2.5px" }}
            class="overflow-hidden absolute rounded-md z-[-1]"
          >
            <div class={styles["editor-gradient-animation"]} />
          </Motion.div>
        </Show>
      </Presence>
      <Presence>
        <Show when={true}>
          <Motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              filter: props.empty ? "blur(12px)" : "blur(5px)",
            }}
            exit={{ opacity: 0 }}
            class="overflow-hidden absolute inset-0 rounded-md z-[-1]"
          >
            <div class={styles["editor-gradient-animation"]} />
          </Motion.div>
        </Show>
      </Presence>
    </>
  );
};

export const GradientBorder: Component<{
  inset?: string;
}> = (props) => (
  <div
    style={{ inset: props.inset ?? "-2.5px" }}
    class="overflow-hidden absolute rounded-md z-[-1] pointer-events-none"
  >
    <div class={styles["gradient-border"]} />
  </div>
);
